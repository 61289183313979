import {
  OrderItemStatus,
  SessionState,
  SessionTypes,
  LicenseSegmentType,
  integrationProviders,
  CountryCodes,
  integrationTypes,
  Plans,
  Currencies,
  locales,
  transactionAction,
  TipStatus,
  FeeStrategies,
  paymentMethodTypes,
  BookingStatus,
} from "./enums";
import { TLicense, TLicenseSegment } from "./yup/Business";
import { TPlaceBookingSettings } from "./yup/Place";
import countries, { SUPPORTED_COUNTRIES } from "./countries";
import { TBooking } from "./yup/Booking";

export const simpleDateFormat = "MM/dd/yyyy";

export const DEFAULT_TIMEZONE = "Europe/London";

export const DEFAULT_CURRENCY = Currencies.GBP;

export const dateRegex = new RegExp(
  "^((0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)\\d\\d)$"
);

export const DEVICE_LOGIN_TOKEN_EXP_SEC = 60 * 3;

export const MAX_ORDER_NUMBER = 500;

export const NON_PENDINGS_SESSION_TYPES = [
  SessionTypes.counter,
  SessionTypes.kiosk,
];

export const IGNORE_ORDER_ITEM_STATUS = [
  OrderItemStatus.CANCELED,
  OrderItemStatus.REJECTED,
  OrderItemStatus.REMOVED,
];

export const CANCELABLE_TRANSACTION_METHODS = [
  paymentMethodTypes.CASH,
  paymentMethodTypes.OTHER,
];

export const FINAL_TRNSACTION_ACTIONS = [
  transactionAction.CHARGE,
  transactionAction.REFUND,
];

export const EGLIBLE_ORDER_ITEM_STATUS = [
  OrderItemStatus.ACCEPTED,
  OrderItemStatus.APPROVED,
  OrderItemStatus.PREPARE,
  OrderItemStatus.READY,
  OrderItemStatus.DELIVERING,
  OrderItemStatus.SUPPLIED,
];

export const ENDED_ORDER_ITEM_STATUS: OrderItemStatus[] = [
  OrderItemStatus.REJECTED,
  OrderItemStatus.REMOVED,
  OrderItemStatus.CANCELED,
  OrderItemStatus.SUPPLIED,
  OrderItemStatus.APPROVED,
  OrderItemStatus.ACCEPTED,
];

export const ALL_SESSION_TYPES = Object.values(SessionTypes);

export const shouldWaitlistSocketCustomer = (booking: TBooking) => {
  if (!booking || booking.segment !== "waitlist") return false;

  if (
    booking.status === BookingStatus.PENDING ||
    booking.status === BookingStatus.CONFIRMED
  ) {
    return true;
  }
  return false;
};

export enum OrderItemType {
  null,
  "modifier",
  "subItem",
}

export const DEFAULT_PLACE_BOOKING: TPlaceBookingSettings = {
  enableNotify: false,

  minutesSlot: 90,
  minutesInterval: 15,
  maxDaysAdvance: 21,

  maxGuests: 10,
  minGuests: 1,
  autoSession: false,
  blockMinAhead: 0,

  mayContact: true,

  messages: {
    confirmSms: null,
    cancelSms: null,
    reminderSms: null,
    reminderMinutes: null,
    fullyBooked: null,
  },
};

export const CANCELABLE_ORDER_ITEM_STATUS: OrderItemStatus[] = [
  OrderItemStatus.APPROVED,
  OrderItemStatus.SUPPLIED,
  OrderItemStatus.ACCEPTED,
  OrderItemStatus.PREPARE,
  OrderItemStatus.READY,
  OrderItemStatus.DELIVERING,
];

export const SYNCED_SESSION_STATES = [
  SessionState.active,
  SessionState.accepted,
  SessionState.approved,
  SessionState.delivering,
  SessionState.ready,
];

export const OPERATOR_CANCELABLE_TIP_STATUS = [
  TipStatus.ELIGBLE,
  TipStatus.PENDING,
];
export const CONSUMER_CANCELABLE_TIP_STATUS = [TipStatus.PENDING];

export const HTTP_LOCALE_HEADER = "x-client-locale";
export const HTTP_CLIENT_APP_HEADER = "x-client-app";
export const HTTP_CLIENT_ID_HEADER = "x-client-id";
export const HTTP_RECAPTCHA_HEADER = "x-recaptcha-token";

export const HTTP_ACCESS_HEADER = "authorization";
export const HTTP_REFRESH_HEADER = "x-refresh-token";
export const HTTP_ERROR_EVENT_ID = "x-error-event-id";

// @deprecated
export const HTTP_AUTH_HEADER = HTTP_ACCESS_HEADER;

export const HTTP_TOKEN_EXPIRE_CODE = 489;

export const HTTP_REFRESH_COOKIE = "xrf";
export const HTTP_FINGERPRINT_COOKIE = "xfp";

export const CLIENT_ID_LOCAL_KEY = "ucid";
export const DEMO_BUSINESS_ID = "ckecqgmo5000208mm7lta19bd";

export const REFRESH_TOKEN_ROUTE = {
  method: "POST",
  path: "/auth/token/refresh",
};

// @deprecated
export const DEVICE_AUTH_HTTP_HEADER = "x-device-access-token";

export const SESSION_STATE_ENDED = [
  SessionState.complete,
  SessionState.canceled,
  SessionState.rejected,
];

export const ACTIVE_OUTGOING_SESSION_STATES = [
  SessionState.approved,
  SessionState.accepted,
  SessionState.active,
  SessionState.ready,
  SessionState.delivering,
];

export const SORTED_SESSION_STATES = [
  SessionState.draft, // draft
  SessionState.approved, // approved by client - outgoing.
  SessionState.accepted, // accepted by operator - outgoing.
  SessionState.active, // prep for outgoing or default in seat.
  SessionState.ready, // ready to pick by cuierer (delivery) or client (pickup)
  SessionState.delivering, // delivering
  SessionState.complete, // order completed
];

export const HIGHER_TIER_CARD_BRANDS = ["amex"];

export const EEA_PRICED_COUNTRIES = [
  CountryCodes.Austria,
  CountryCodes.Belgium,
  CountryCodes.Bulgaria,
  CountryCodes.Croatia,
  CountryCodes.Cyprus,
  CountryCodes.CzechRepublic,
  CountryCodes.Denmark,
  CountryCodes.Estonia,
  CountryCodes.Finland,
  CountryCodes.France,
  CountryCodes.Germany,
  CountryCodes.Greece,
  CountryCodes.Hungary,
  CountryCodes.Ireland,
  CountryCodes.Italy,
  CountryCodes.Latvia,
  CountryCodes.Lithuania,
  CountryCodes.Luxembourg,
  CountryCodes.Malta,
  CountryCodes.Netherlands,
  CountryCodes.Poland,
  CountryCodes.Portugal,
  CountryCodes.Romania,
  CountryCodes.Slovakia,
  CountryCodes.Slovenia,
  CountryCodes.Spain,
  CountryCodes.Sweden,
  CountryCodes.Norway,
  CountryCodes.Iceland,
  CountryCodes.Liechtenstein,
];

export const defaultLicenseSegments: TLicenseSegment[] = [
  {
    id: "default",
    type: LicenseSegmentType.card,
    base: {
      minMonthlyFee: 0,
      strategy: FeeStrategies.TWO_TIERS,
      struct: {
        low: {
          fixed: 0.15,
          percent: 0.016,
        },
        high: {
          fixed: 0.15,
          percent: 0.026,
        },
      },
    },
  },
  {
    id: "default",
    type: LicenseSegmentType.card_present,
    base: {
      minMonthlyFee: 0,
      strategy: FeeStrategies.TWO_TIERS,
      struct: {
        low: {
          fixed: 0.15,
          percent: 0.016,
        },
        high: {
          fixed: 0.15,
          percent: 0.026,
        },
      },
    },
  },
];

export const defaultUnitedStatesLicenseSegments: TLicenseSegment[] = [
  {
    id: "default",
    type: LicenseSegmentType.card,
    base: {
      minMonthlyFee: 0,
      strategy: FeeStrategies.TWO_TIERS,
      struct: {
        low: {
          fixed: 0.22,
          percent: 0.027,
        },
        high: {
          fixed: 0.22,
          percent: 0.037,
        },
      },
    },
  },
  {
    id: "default",
    type: LicenseSegmentType.card_present,
    base: {
      minMonthlyFee: 0,
      strategy: FeeStrategies.TWO_TIERS,
      struct: {
        low: {
          fixed: 0.22,
          percent: 0.027,
        },
        high: {
          fixed: 0.22,
          percent: 0.037,
        },
      },
    },
  },
];

export function getActiveLicense(licenses: TLicense[]) {
  return licenses
    .filter((l) => l.active)
    .find((l) => {
      if (!l.validThrough && !l.validFrom) return true;
      const now = new Date();

      if (l.validFrom) {
        const from = new Date(l.validFrom);
        if (from > now) return false;
      }

      if (l.validThrough) {
        const through = new Date(l.validThrough);
        if (through < now) return false;
      }

      return true;
    });
}

export const defaultLicenseSegmentsByCountry: Record<
  string,
  TLicenseSegment[]
> = {
  default: defaultLicenseSegments,
  [CountryCodes.UnitedStates]: defaultUnitedStatesLicenseSegments,
};

export const DEFAULT_ESC_WIDTH = 570;

export const STEPS_BY_TYPE = {
  [SessionTypes.delivery]: [
    SessionState.approved, // approved by client - outgoing.
    SessionState.accepted, // accepted by operator - outgoing.
    SessionState.active, // prep for outgoing or default in seat.
    SessionState.ready, // ready to pick by cuierer (delivery) or client (pickup)
    SessionState.delivering, // delivering
    SessionState.complete, // order completed
  ],
  [SessionTypes.pickup]: [
    SessionState.approved, // approved by client - outgoing.
    SessionState.accepted, // accepted by operator - outgoing.
    SessionState.active, // prep for outgoing or default in seat.
    SessionState.ready, // ready to pick by cuierer (delivery) or client (pickup)
    SessionState.complete, // order completed
  ],
};

export const DEFAULT_PAYMENT_STEP = {
  [SessionTypes.delivery]: SessionState.approved,
  [SessionTypes.pickup]: SessionState.approved,
  [SessionTypes.seat]: SessionState.active,
};

export const CONSUMER_ACTIVE_SESSION_STATES = [
  SessionState.draft,
  SessionState.active,
];

export const INGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE = [
  SessionState.draft,
  SessionState.active,
];

export const OUTGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE = [
  SessionState.draft,
];

export const INGOING_SESSION_TYPES = [SessionTypes.seat];

export const OUTGOING_SESSION_TYPES = ALL_SESSION_TYPES.filter(
  (t) => !INGOING_SESSION_TYPES.includes(t)
);

export const LOCALES_TRANSLATIONS = {
  [locales.en_US]: "English, US",
  [locales.en_GB]: "English, UK",
  [locales.en_AU]: "English, AU",
  [locales.es_ES]: "Español",
  [locales.de_DE]: "Deutsche",
  [locales.fr_FR]: "Français",
  // [locales.he_IL]: "עברית",
};

export const DEFAULT_LANGUAGES = {
  [CountryCodes.UnitedStates]: locales.en_US,
  [CountryCodes.Canada]: locales.en_US,
  [CountryCodes.UnitedKingdom]: locales.en_GB,
  [CountryCodes.Australia]: locales.en_US,
  // [CountryCodes.Israel]: locales.he_IL,
  [CountryCodes.Spain]: locales.es_ES,
  [CountryCodes.France]: locales.fr_FR,
  [CountryCodes.Germany]: locales.de_DE,
};

export const DEFAULT_LEGAL_TYPE = {
  [CountryCodes.Canada]: "CCPC",
  [CountryCodes.UnitedStates]: "INC",
  [CountryCodes.UnitedKingdom]: "LTD",
  [CountryCodes.Australia]: "LTD",
  [CountryCodes.Israel]: "LTD",
  [CountryCodes.Spain]: "SL",
  [CountryCodes.France]: "SARL",
  [CountryCodes.Germany]: "GMBH",
};

export const isSessionIngoing = (sessionType: string | SessionTypes | any) =>
  INGOING_SESSION_TYPES.includes(sessionType);

export const isSessionAcceptAddOrderItem = ({ state, type }) => {
  const shouldShowStates = isSessionIngoing(type)
    ? INGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE
    : OUTGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE;
  return shouldShowStates.includes(state);
};

export const isSessionAcceptConsumerMutations = ({ state, type }) => {
  const shouldShowStates = isSessionIngoing(type)
    ? INGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE
    : OUTGOING_CAN_ADD_ORDER_ITEM_IN_SESSION_STATE;
  return shouldShowStates.includes(state);
};

export const isSessionCanInviteUser = ({ state, type }) => {
  const isIngoing = isSessionIngoing(type);
  return isIngoing ? state === SessionState.ready : false;
};

export const userFields = [
  "name.first",
  "name.last",
  "email",
  "mobile",
] as const;

export const MOBILE_CHALLENGE = {
  MAX_ATTEMPTS: 3,
  CODE_LENGTH: 5,
  EXPIRE_AFTER: "2m",
};

export const WEEKDAYS_INDEX = [1, 2, 3, 4, 5, 6, 7]; // [ Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday ]
export const JS_WEEKDAYS_INDEX = [7, 1, 2, 3, 4, 5, 6]; // [ Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday ]

export const HOURS_ALL_DAY_OPEN = {
  opens: "00:00",
  closes: "23:59",
};

export const HOURS_ALL_DAY_CLOSES = {
  opens: "00:00",
  closes: "00:00",
};

export const invoiceIntegrations = [
  integrationProviders.HESHBONIT_ONLINE,
  integrationProviders.XERO,
];

export const paymentsIntegrations = [integrationProviders.STRIPE_CONNECT];

export const orderIntegrations = [integrationProviders.DELIVEROO];

export const availableIntegration = [
  {
    provider: integrationProviders.STRIPE_CONNECT,
    defaultName: "Stripe Connect",
    countries: [CountryCodes.UnitedKingdom] as string[],
    types: [integrationTypes.PAYMENTS],
  },
  // {
  //   provider: integrationProviders.XERO,
  //   defaultName: "XERO",
  //   countries: [CountryCodes.UnitedKingdom] as string[],
  //   types: [integrationTypes.ACCOUNTING],
  // },
  {
    provider: integrationProviders.DELIVEROO,
    countries: [CountryCodes.UnitedKingdom] as string[],
    types: [integrationTypes.ORDERS],
  },
];

export const MAX_ORDER_ITEM_NOTE_LENGTH = 120;

const END_OF_PROMOTION = new Date("2021-07-30T23:59:59.000Z");

const getPlanTerms = (plan: Plans, curr: Currencies) => {
  if (plan === Plans.MENU) {
    switch (curr) {
      case Currencies.ILS:
        return {
          value: 0,
          // afterValue: 49,
          type: "currency",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.EUR:
        return {
          value: 0,
          // afterValue: 11,
          type: "currency",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.AUD:
        return {
          value: 0,
          // afterValue: 17,
          type: "currency",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.GBP:
        return {
          value: 0,
          afterValue: 8,
          type: "currency",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      default:
        return {
          value: 0,
          // afterValue: 13,
          type: "currency",
          currency: Currencies.USD,
          until: END_OF_PROMOTION,
        };
    }
  } else if (plan === Plans.ORDERS) {
    switch (curr) {
      case Currencies.ILS:
        return {
          value: 0.01,
          afterValue: 0.015,
          monthlyMin: 49,
          type: "percent",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.EUR:
        return {
          value: 0.01,
          afterValue: 0.015,
          monthlyMin: 0,
          type: "percent",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.AUD:
        return {
          value: 0.01,
          afterValue: 0.015,
          monthlyMin: 0,
          type: "percent",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      case Currencies.GBP:
        return {
          value: 0.005,
          afterValue: 0.015,
          monthlyMin: 0,
          type: "percent",
          currency: curr,
          until: END_OF_PROMOTION,
        };
      default:
        return {
          value: 0.01,
          afterValue: 0.015,
          monthlyMin: 0,
          type: "percent",
          currency: Currencies.USD,
          until: END_OF_PROMOTION,
        };
    }
  }
};

const createPlatformPlan = (options: { cc: string; plan: Plans }) => {
  const curr = countries[options.cc]?.currency || countries["GB"].currency;
  return getPlanTerms(options.plan, curr as any);
};

export const AVAILABLE_PLANS = [Plans.MENU, Plans.ORDERS];

export const PlatformPlans = SUPPORTED_COUNTRIES.reduce(
  (res, cc) => ({
    ...res,
    [cc]: AVAILABLE_PLANS.reduce(
      (plans, plan) => ({
        ...plans,
        [plan]: createPlatformPlan({ cc, plan }),
      }),
      {} as Record<Plans, ReturnType<typeof createPlatformPlan>>
    ),
  }),
  {} as Record<
    CountryCodes,
    Record<Plans, ReturnType<typeof createPlatformPlan>>
  >
);

export const ALLERGIES_NUTS = [
  "brazil",
  "pecan",
  "pistachio",
  "cashew",
  "macadamia",
  "walnuts",
  "almonds",
  "hazlenuts",
];
export const ALLERGIES_GLUTEN = ["barley", "oats", "rye", "wheat", "spelt"];

export const ALLERGIES = [
  "gluten",
  "nuts",
  "peanuts",
  "sesame",
  "celery",
  "crustaceans",
  "molluscs",
  "fish",
  "lupin",
  "eggs",
  "milk",
  "mustard",
  "soya",
  "sulphites",
] as const;

export const ITEM_FLAVORS = [
  "sweet",
  "sour",
  "salty",
  "mild",
  "spicy",
  "fruity",
] as const;

export const DIETS = [
  "vegan",
  "vegetarian",
  "kosher",
  "halal",
  "keto",
  "paleo",
] as const;

export const ITEM_TAGS = ["popular", "new", "recommended"] as const;

export const prefSymbols = {
  diets: {
    kosher: "K",
    halal: "H",
    vegan: "VE",
    vegetarian: "V",
  },
  allergies: {
    gluten: "🍞",
    nuts: "🌰",
    peanuts: "🥜",
    sesame: "🫓",
    celery: "🥬",
    crustaceans: "🦐",
    molluscs: "🦑",
    lupin: "🌾",
    fish: "🐟",
    eggs: "🥚",
    milk: "🥛",
    mustard: "🌼",
    soya: "🍢",
    sulphites: "🍷",
  },
};

export const AppEvents = [
  // join wiz
  "join_wizard_started",
  "join_wizard_license_accepted",
  "join_wizard_license_rejected",
  "join_wizard_completed",

  // auth
  "auth_login_google_submit",
  "auth_login_google_failed",

  "auth_login_mobile_number_submit",
  "auth_login_mobile_number_failed",

  "auth_login_mobile_verify_submit",
  "auth_login_mobile_verify_failed",

  "auth_login_success",
  "auth_signup_success",

  "auth_guest",

  // session
  "session_client_opened",

  "session_client_item_open",
  "session_client_item_added",

  "session_client_oi_open",
  "session_client_oi_removed",
  "session_client_oi_updated",

  "session_client_payment_submit",
  "session_client_payment_success",
  "session_client_payment_failed",

  "session_client_feedback_submitted",
  "session_client_feedback_failed",
  "session_client_order_again",

  // booking
  "booking_search",
  "booking_search_failed",
  "booking_search_success",
  "booking_slot_selected",
  "booking_submit",
  "booking_submit_success",
  "booking_submit_failed",
  "booking_canceled",
  "booking_directions",

  // waitlist
  "booking_waitlist_submit",
  "booking_waitlist_submit_success",
  "booking_waitlist_submit_failed",

  // notify
  "booking_notify_slot_selected",

  // membership_join
  "membership_join_submit",
  "membership_join_submit_success",
  "membership_join_submit_failed",

  // site
  "site_demo_qr_click",
  "site_contact_success",
  "site_contact_failed",

  // general
  "screen_view",
] as const;

export type AppEvent = (typeof AppEvents)[number];
